<template>
    <v-container>
        <new-payment
            v-if="showNewModal"
            :currency="payment.currency_id ? payment.currency.currency : user.company.currency"
            :pending="payment.totalCreditCollected"
            :show-all="false"
            @addPayment="createPayment"/>
        <v-row>
            <v-col
                class="py-0"
                cols="12"
            >
                <app-data-table
                    :title="showTitle ? titleTable : ''"
                    :select-data="selectData"
                    csv-filename="SupplierToPay"
                    :headers="getTableColumns"
                    :items="creditsBuys"
                    :second-date-data="new Date(new Date().getTime() + 31 * 24 * 60 * 60 * 1000)"
                    :first-date-data="new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000)"
                    :manager="'client'"
                    :sort-by="['firstName']"
                    :sort-desc="[false, true]"
                    :viewShowFilterDate="true"
                    multi-sort
                    :options="vBindOption"
                    :multi-select=true
                    :viewShowButton="false"
                    :viewNewButton="false"
                    :viewPayButton="true"
                    :viewEditButton="false"
                    :viewDeleteButton="false"
                    @reload-data="loadInitData($event)"
                >
                    <template v-slot:[`item.totalCredit`]="{ item }">
                      {{ parseFloat(item.totalCredit).toFixed(2) }} {{getCreditPay(item).currency_id ? getCreditPay(item).currency.currency : user.getCurrency}}
                    </template>
                  <template v-slot:[`item.totalCreditPay`]="{ item }">
                    {{ parseFloat(item.totalCreditPay).toFixed(2) }} {{ getCreditPay(item).currency_id ? getCreditPay(item).currency.currency : getCurrency }}
                    </template>
                    <template
                        v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded}"
                    ><v-btn
                        color="primary"
                        fab
                        x-small
                        dark
                        @click="expand(!isExpanded)"
                    >
                        <v-icon v-if="isExpanded">
                            mdi-chevron-up
                        </v-icon>
                        <v-icon v-else>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td
                            :colspan="headers.length"
                            style="padding: 10 10 10 10"
                        >
                          <credit-pay :credits-buys="getCreditPay(item)" @showNewPay="showNewPay" @cancelPay="cancelPay" style="margin: 10"/>
                        </td>
                    </template>
                </app-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import NewPayment from '../../pay/NewPay'
import CreditPay from './credit-supplier/credit-pay'

export default {
  name: 'SupplierToPay',
  components: { CreditPay, NewPayment },
  data () {
    return {
      selectData: [],
      showPay: false,
      payment: null,
      pendig: 0.00,
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: true
      },
      titleTable: this.$vuetify.lang.t('$vuetify.menu.account_to_pay')
    }
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    this.loadInitData(null)
  },
  computed: {
    ...mapState('buy', ['creditsBuys']),
    ...mapState('payment', ['showNewModal']),
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.tax.noFacture'),
          value: 'no_facture',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'supplier.name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pay.total_to_pay'),
          value: 'totalCredit',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pay.total_pay'),
          value: 'totalCreditPay',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pay.outstanding'),
          value: 'outstanding',
          select_filter: false
        }
      ]
    }
  },
  methods: {
    ...mapActions('buy', ['getCreditBuysToPay']),
    ...mapActions('payment', ['toogleNewModal', 'createPayPayment', 'deletePay']),
    getCreditPay (item) {
      return item.pays.filter(py => py.bank_payment.payment.name === 'credit').filter(cr => parseFloat(cr.cant) > 0)
    },
    async loadInitData (filter) {
      // this.loadingData = true
      await this.getCreditBuysToPay(filter)
    },
    showNewPay (payments, payment) {
      this.payment = payments
      this.toogleNewModal(true)
    },
    async cancelPay (id) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.pay.pay')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) this.deletePay({ id: id, type: 'buy' })
        })
    },
    createPayment (pay) {
      pay.pay_sale = this.payment
      this.toogleNewModal(false)
      this.createPayPayment({ pay: pay, type: 'buy' }).then(() => {
        this.payment = null
        this.payments = []
      })
    }
  }
}
</script>

<style scoped>

</style>
