var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"550","persistent":""},model:{value:(_vm.toogleNewModal),callback:function ($$v) {_vm.toogleNewModal=$$v},expression:"toogleNewModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.titles.new', [ _vm.$vuetify.lang.t('$vuetify.payment.name') ])))])]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.payment.cant_charge') + ': ' + parseFloat(this.pending).toFixed(2) + ' ' + _vm.user.company.currency)+" ")]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"my-10",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",staticStyle:{"margin-bottom":"10px"},attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"filter":_vm.customFilter,"items":_vm.payments,"label":_vm.$vuetify.lang.t('$vuetify.payment.name'),"rules":_vm.formRule.required,"auto-select-first":"","item-text":"name","return-object":"","hint":_vm.$vuetify.lang.t('$vuetify.hints.gateway_system'),"persistent-hint":""},model:{value:(_vm.pay.bank_payment),callback:function ($$v) {_vm.$set(_vm.pay, "bank_payment", $$v)},expression:"pay.bank_payment"}})],1),(_vm.pay.bank_payment ? _vm.pay.bank_payment.method === 'cash' || _vm.pay.bank_payment.method === 'credit': false)?_c('v-col',{staticClass:"py-0",staticStyle:{"margin-bottom":"10px"},attrs:{"cols":"12","md":"6"}},[_c('v-text-field-money',{staticStyle:{"margin-top":"8px"},attrs:{"label":_vm.$vuetify.lang.t('$vuetify.variants.cant'),"options":{
                length: 15,
                precision: 2,
                empty: 0.0,
                min:0.00,
                max:_vm.pending
              },"properties":{
                prefix: _vm.currency,
                hint:_vm.$vuetify.lang.t('$vuetify.hints.round', 2, parseFloat(_vm.pay.cant).toFixed(2)),
                max:_vm.pending,
                min:0.00,
              },"rules":_vm.formRule.required,"value":_vm.pending,"required":""},model:{value:(_vm.pay.cant),callback:function ($$v) {_vm.$set(_vm.pay, "cant", $$v)},expression:"pay.cant"}})],1):_vm._e(),(_vm.pay.bank_payment ? _vm.pay.bank_payment.method !== 'credit': true)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","no-gutters":""}},[[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.currencies.length > 0)?_c('v-col',{attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.currency'),"items":_vm.currencies,"auto-select-first":"","item-text":"currency","required":"","return-object":""},on:{"change":_vm.calcDifference},model:{value:(_vm.pay.currency),callback:function ($$v) {_vm.$set(_vm.pay, "currency", $$v)},expression:"pay.currency"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.currencies.length > 0 ? 7 : 12}},[_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.payment.cant_pay'),"options":{
                      length: 15,
                      precision: 2,
                      empty: 0.0
                    },"properties":{
                      prefix: _vm.currencies.length === 0  ? _vm.currency : '',
                      hint:_vm.$vuetify.lang.t('$vuetify.hints.round', 2, parseFloat(_vm.pay.cant_pay).toFixed(2))
                    },"rules":_vm.formRule.required,"value":_vm.pending,"required":""},model:{value:(_vm.pay.cant_pay),callback:function ($$v) {_vm.$set(_vm.pay, "cant_pay", $$v)},expression:"pay.cant_pay"}})],1)],1)]],2):_vm._e(),(_vm.pay.bank_payment.method === 'cash' && _vm.pay.cant_back >= 0)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.payment.cant_back'
                ),"options":{
                length: 15,
                precision: 2,
                empty: 0.0
              },"properties":{
                prefix: _vm.currency,
                clearable: true
              },"value":_vm.pending,"readonly":""},model:{value:(_vm.pay.cant_back),callback:function ($$v) {_vm.$set(_vm.pay, "cant_back", $$v)},expression:"pay.cant_back"}})],1):_vm._e(),(_vm.pay.currency.currency !== _vm.user.company.currency && _vm.cantToPay!==0.00)?_c('v-col',{attrs:{"md":"12","cols":"12"}},[(parseFloat(_vm.getExactValue().two_decimals).toFixed(2) !==parseFloat(_vm.cantToPay).toFixed(2))?_c('p',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.messages.info_change_rate_1', [_vm.user.company.currency + ' ' + parseFloat(_vm.pay.currency.change).toFixed(2)], [_vm.pay.currency.currency + ' ' + _vm.getExactValue().six_decimals], [_vm.getExactValue().two_decimals]))+" "),_c('br'),_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.messages.info_change_rate_2', [_vm.getExactValue().two_decimals ], [parseFloat(_vm.pay.currency.change).toFixed(2)], [_vm.getExactValue().lost], [parseFloat(_vm.pay.cant).toFixed(2)], [_vm.getExactValue().difference]))+" ")]):_vm._e(),_c('h3',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.messages.info_change_rate_3', [_vm.pay.currency.currency + ' ' + parseFloat(_vm.cantToPay).toFixed(2)], [_vm.user.company.currency + ' ' + _vm.getExactValue().cant_back])))])]):_vm._e(),(_vm.pay.bank_payment.payment_id && _vm.pay.bank_payment.type !== 'counted')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290px","min-width":"290px","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$vuetify.lang.t(
                      '$vuetify.payment.pay_before'
                    ),"persistent-hint":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,4068076549),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.pay.mora),callback:function ($$v) {_vm.$set(_vm.pay, "mora", $$v)},expression:"pay.mora"}})],1)],1):_vm._e(),(_vm.pay.bank_payment.payment_id && _vm.pay.bank_payment.type !== 'counted')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.payment.pay_delay'
                ),"options":{
                length: 15,
                precision: 2,
                empty: 0.0
              },"properties":{
                prefix: _vm.currency,
                clearable: true
              },"rules":_vm.formRule.required,"value":_vm.pending,"required":""},model:{value:(_vm.pay.cantMora),callback:function ($$v) {_vm.$set(_vm.pay, "cantMora", $$v)},expression:"pay.cantMora"}})],1):_vm._e(),(_vm.pay.bank_payment.payment_id && _vm.pay.bank_payment.method === 'check')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.firstName'),"rules":_vm.formRule.required,"required":"","autofocus":""},model:{value:(_vm.pay.check_emited),callback:function ($$v) {_vm.$set(_vm.pay, "check_emited", $$v)},expression:"pay.check_emited"}})],1):_vm._e(),(_vm.pay.bank_payment.payment_id && _vm.pay.bank_payment.method === 'check')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.payment.check_number'),"rules":_vm.formRule.required,"required":"","autofocus":""},model:{value:(_vm.pay.check_number),callback:function ($$v) {_vm.$set(_vm.pay, "check_number", $$v)},expression:"pay.check_number"}})],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":_vm.isActionInProgress},on:{"click":function($event){return _vm.toogleNewModal(false)}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.actions.cancel'))+" ")],1),_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":!_vm.formValid || _vm.isActionInProgress || !_vm.valid,"loading":_vm.isActionInProgress,"color":"primary"},on:{"click":_vm.savePayment}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.actions.save'))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }