<template>
  <app-data-table
    csv-filename="SupplierToPay"
    :headers="getTableColumns"
    :hideFooter="true"
    :items="creditsBuys"
    :second-date-data="new Date(new Date().getTime() + 31 * 24 * 60 * 60 * 1000)"
    :first-date-data="new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000)"
    :manager="'client'"
    :viewShowFilter="false"
    :sort-by="['firstName']"
    :sort-desc="[false, true]"
    multi-sort
    :options="vBindOption"
    :viewShowButton="false"
    :viewNewButton="false"
    :viewPayButton="true"
    :viewEditButton="false"
    :viewDeleteButton="false"
    @new-pay="$emit('showNewPay', $event, creditsBuys[0]['sale_id'])"
  >
    <template v-slot:[`item.pays`]="{ item }">
      {{ new Date(item.mora).toLocaleDateString() }}
    </template>
    <template v-slot:[`item.lastName`]="{ item }">
      {{ parseFloat(item.cant).toFixed(2) }} {{item.currency_id ? item.currency.currency : getCurrency }}
    </template>
    <template v-slot:[`item.firstName`]="{ item }">
      {{ item.cantMora ? parseFloat(item.cantMora).toFixed(2) : '0.00'}} {{item.currency_id ? item.currency.currency : getCurrency}}
    </template>
    <template v-slot:[`item.totalCreditPay`]="{ item }">
      {{ parseFloat(item.totalCreditPay).toFixed(2) }} {{item.currency_id ? item.currency.currency : user.getCurrency}}
    </template>
    <template v-slot:[`item.totalCreditCollected`]="{ item }">
      {{ parseFloat(item.totalCreditCollected).toFixed(2) }} {{ item.currency_id ? item.currency.currency : getCurrency }}
    </template>
    <template
      v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded}"
    ><v-btn
      v-if="item.pays.length > 0"
      color="primary"
      fab
      x-small
      dark
      @click="expand(!isExpanded)"
    >
      <v-icon v-if="isExpanded">
        mdi-chevron-up
      </v-icon>
      <v-icon v-else>
        mdi-chevron-down
      </v-icon>
    </v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td
        :colspan="headers.length"
        style="padding: 0 0 0 0"
      >
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                {{ $vuetify.lang.t('$vuetify.payment.name') }}</th>
              <th class="text-left">
                {{ $vuetify.lang.t("$vuetify.payment.cant_pay") }}</th>
              <th class="text-left">
                {{ $vuetify.lang.t("$vuetify.payment.cant_charge") }}</th>

              <th class="text-left">
                {{ $vuetify.lang.t("$vuetify.payment.cant_back") }}
              </th>

              <th class="text-left">
                {{ $vuetify.lang.t('$vuetify.actions.actions') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="pay in item.pays"
                :key="pay.id">
              <td>{{ $vuetify.lang.t( '$vuetify.payment.' + pay.method) }}</td>
              <td>{{ parseFloat(pay.cant).toFixed(2) }}</td>
              <td>{{parseFloat(pay.cant + pay.cant_back).toFixed(2)}}</td>
              <td>{{pay.cant_back ? parseFloat(pay.cant_back).toFixed(2) : '0.00'}}</td>
              <td>
                <v-icon
                  small
                  @click="$emit('cancelPay',pay.id)"
                >
                  mdi-delete
                </v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </template>
  </app-data-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'credit-pay',
  data () {
    return {
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: true
      }
    }
  },
  props: {
    creditsBuys: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.payment.pay_before'),
          value: 'pays',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.payment.cant_charge'),
          value: 'lastName',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.payment.pay_delay'),
          value: 'firstName',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pay.total_pay'),
          value: 'totalCreditPay',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pay.total_to_pay'),
          value: 'totalCreditCollected',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
