<template>
  <v-dialog
    v-model="toogleNewModal"
    max-width="550"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          $vuetify.lang.t('$vuetify.titles.new', [
            $vuetify.lang.t('$vuetify.payment.name')
          ])
        }}</span>
      </v-card-title>
      <v-card-subtitle>
        {{ $vuetify.lang.t('$vuetify.payment.cant_charge') + ': ' + parseFloat(this.pending).toFixed(2) + ' ' + user.company.currency }}
      </v-card-subtitle>
      <v-card-text>
        <v-form
          ref="form"
          v-model="formValid"
          class="my-10"
          lazy-validation
        >
          <v-row>
            <v-col
              class="py-0"
              cols="12"
              style="margin-bottom: 10px"
              md="6"
            >
              <v-autocomplete
                v-model="pay.bank_payment"
                :filter="customFilter"
                :items="payments"
                :label="$vuetify.lang.t('$vuetify.payment.name')"
                :rules="formRule.required"
                auto-select-first
                item-text="name"
                return-object
                :hint="$vuetify.lang.t('$vuetify.hints.gateway_system')"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="pay.bank_payment ? pay.bank_payment.method === 'cash' || pay.bank_payment.method === 'credit': false"
              style="margin-bottom: 10px"
              class="py-0"
              cols="12"
              md="6"
            >
              <v-text-field-money
                v-model="pay.cant"
                :label="
                  $vuetify.lang.t('$vuetify.variants.cant')
                "
                :options="{
                  length: 15,
                  precision: 2,
                  empty: 0.0,
                  min:0.00,
                  max:pending
                }"
                :properties="{
                  prefix: currency,
                  hint:$vuetify.lang.t('$vuetify.hints.round', 2, parseFloat(pay.cant).toFixed(2)),
                  max:pending,
                  min:0.00,
                }"
                :rules="formRule.required"
                :value="pending"
                required
                style="margin-top: 8px"
              />
            </v-col>
            <v-col
              v-if="pay.bank_payment ? pay.bank_payment.method !== 'credit': true"
              class="py-0"
              cols="12"
              md="6"
              no-gutters
            >
              <template>
                <v-row no-gutters>
                  <v-col
                    v-if="currencies.length > 0"
                    cols="5"
                  >
                    <v-autocomplete
                      v-model="pay.currency"
                      :label="$vuetify.lang.t('$vuetify.currency')"
                      :items="currencies"
                      auto-select-first
                      item-text="currency"
                      required
                      return-object
                      @change="calcDifference"
                    />
                  </v-col>
                  <v-col
                    :cols="currencies.length > 0 ? 7 : 12"
                  >
                    <v-text-field-money
                      v-model="pay.cant_pay"
                      :label="$vuetify.lang.t('$vuetify.payment.cant_pay')"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.0
                      }"
                      :properties="{
                        prefix: currencies.length === 0  ? currency : '',
                        hint:$vuetify.lang.t('$vuetify.hints.round', 2, parseFloat(pay.cant_pay).toFixed(2))
                      }"
                      :rules="formRule.required"
                      :value="pending"
                      required
                    />
                  </v-col>
                </v-row>
              </template>
            </v-col>
            <v-col
              v-if="pay.bank_payment.method === 'cash' && pay.cant_back >= 0"
              class="py-0"
              cols="12"
              md="6"
            >
              <v-text-field-money
                v-model="pay.cant_back"
                :label="
                  $vuetify.lang.t(
                    '$vuetify.payment.cant_back'
                  )
                "
                :options="{
                  length: 15,
                  precision: 2,
                  empty: 0.0
                }"
                :properties="{
                  prefix: currency,
                  clearable: true
                }"
                :value="pending"
                readonly
              />
            </v-col>
            <v-col md="12" cols="12" v-if="pay.currency.currency !== user.company.currency && cantToPay!==0.00">

              <p v-if="parseFloat(getExactValue().two_decimals).toFixed(2) !==parseFloat(cantToPay).toFixed(2)">
                {{$vuetify.lang.t('$vuetify.messages.info_change_rate_1', [user.company.currency + ' ' + parseFloat(pay.currency.change).toFixed(2)],
                [pay.currency.currency + ' ' + getExactValue().six_decimals], [getExactValue().two_decimals])}}
                <br>{{$vuetify.lang.t('$vuetify.messages.info_change_rate_2', [getExactValue().two_decimals ],
                [parseFloat(pay.currency.change).toFixed(2)], [getExactValue().lost], [parseFloat(pay.cant).toFixed(2)], [getExactValue().difference])}}
                </p>
                  <h3>{{$vuetify.lang.t('$vuetify.messages.info_change_rate_3', [pay.currency.currency + ' ' + parseFloat(cantToPay).toFixed(2)],
                    [user.company.currency + ' ' + getExactValue().cant_back])}}</h3>
              </v-col>
            <v-col
              v-if="pay.bank_payment.payment_id && pay.bank_payment.type !== 'counted'"
              class="py-0"
              cols="12"
              md="6"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    v-bind="attrs"
                    :label="
                      $vuetify.lang.t(
                        '$vuetify.payment.pay_before'
                      )
                    "
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="pay.mora"
                  no-title
                  @input="menu2 = false"
                />
              </v-menu>
            </v-col>
            <v-col
              v-if="pay.bank_payment.payment_id && pay.bank_payment.type !== 'counted'"
              class="py-0"
              cols="12"
              md="6"
            >
              <v-text-field-money
                v-model="pay.cantMora"
                :label="
                  $vuetify.lang.t(
                    '$vuetify.payment.pay_delay'
                  )
                "
                :options="{
                  length: 15,
                  precision: 2,
                  empty: 0.0
                }"
                :properties="{
                  prefix: currency,
                  clearable: true
                }"
                :rules="formRule.required"
                :value="pending"
                required
              />
            </v-col>
            <v-col
              v-if="pay.bank_payment.payment_id && pay.bank_payment.method === 'check'"
              class="py-0"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="pay.check_emited"
                :label="$vuetify.lang.t('$vuetify.firstName')"
                :rules="formRule.required"
                required
                autofocus
              />
            </v-col>

            <v-col
              v-if="pay.bank_payment.payment_id && pay.bank_payment.method === 'check'"
              class="py-0"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="pay.check_number"
                :label="$vuetify.lang.t('$vuetify.payment.check_number')"
                :rules="formRule.required"
                required
                autofocus
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="isActionInProgress"
          class="mb-2"
          @click="toogleNewModal(false)"
        >
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
        </v-btn>
        <v-btn
          :disabled="!formValid || isActionInProgress || !valid"
          :loading="isActionInProgress"
          class="mb-2"
          color="primary"
          @click="savePayment"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $vuetify.lang.t('$vuetify.actions.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'NewPayment',
  props: {
    pending: {
      type: Number,
      default: 0.0
    },
    showAll: {
      type: Boolean,
      default: true
    },
    currency: {
      type: String,
      default: function () {
        return this.user.company.currency
      }
    }
  },
  data () {
    return {
      menu2: false,
      formValid: false,
      payments: [],
      currencies: [],
      cantToPay: 0.00,
      cantToBack: 0.00,
      pay: {
        bank_payment: {
          bank: {},
          payment: {},
          id: null
        },
        cant: '',
        cantMora: 0.00,
        cant_back: 0.00,
        cant_pay: 0.00,
        check_emited: '',
        check_number: '',
        currency: this.currency,
        currency_id: null,
        mora: new Date().toISOString().substr(0, 10),
        method: '',
        delay: 0.00
      },
      bank: {},
      formRule: this.$rules
    }
  },
  computed: {
    ...mapState('exchangeRate', ['saved', 'changes']),
    ...mapState('bank', ['saved', 'banks', 'isActionInProgress']),
    ...mapGetters('auth', ['user']),
    computedDateFormatted () {
      return this.formatDate(this.pay.mora)
    },
    valid () {
      if (this.pay.bank_payment.payment_id) {
        if (this.pay.bank_payment.method === 'cash' && (parseFloat(this.pay.cant_pay).toFixed(2) === '0.00')) { return false }
        if (this.pay.bank_payment.method === 'check' && (this.pay.check_emited === '' || this.pay.check_number === '')) { return false }
        return true
      }
      return false
    }
  },
  watch: {
    'pay.cant': function (val) {
      if (parseFloat(val) > parseFloat(this.pending)) {
        this.cancelChangeCant()
      }
    },
    'pay.cant_pay': function () {
      this.cancelChangeCantPay()
      this.calcDifference()
    },
    'pay.currency': function () {
      if (this.pay.currency.currency !== this.user.company.currency) {
        let cantPay = Math.round((this.pay.cant / this.pay.currency.change) * 100) / 100
        let sum = this.pay.cant
        while (cantPay * this.pay.currency.change < sum) {
          // eslint-disable-next-line no-const-assign
          const rest = Math.round((this.pay.cant - (cantPay * this.pay.currency.change)) * 100) / 100
          sum = sum + rest
          cantPay = Math.round(sum / this.pay.currency.change * 100) / 100
        }
        this.pay.currency_id = this.pay.currency.id
        this.cantToPay = Math.round(cantPay * 100) / 100
        this.cantToBack = parseFloat(this.cantToPay) * this.pay.currency.change - this.pay.cant
      }
    },
    'pay.bank_payment': function () {
      if (this.pay.bank_payment.method !== 'cash') {
        this.cancelChangeCantPay()
      }
      this.calcDifference()
    },
    changes: function () {
      if (this.changes.length > 0) {
        this.currencies = []
        this.currencies.push({
          currency: this.currency,
          change: 1,
          id: ''
        })
        this.changes.forEach(v => {
          this.currencies.push({
            currency: v.currency,
            change: v.change,
            id: v.id
          })
        })
        this.pay.currency = this.currencies[0]
      }
    }
  },
  async mounted () {
    this.formValid = false
    this.pay.name = 'counted'
    this.pay.cant = this.pending
    await this.getChanges()
    await this.getBanks()
    this.banks.forEach((v) => {
      if (v.payments_banks.filter(pv => pv.payment.method === 'cash').length > 0) {
        const pym = v.payments_banks.filter(pv => pv.payment.method === 'cash')[0]
        this.payments.push({
          name: '(' + v.name + ') ' + this.$vuetify.lang.t('$vuetify.payment.' + pym.payment.method),
          type: pym.payment.name,
          method: pym.payment.method,
          bank_id: v.id,
          payment_id: pym.id
        })
      }
      if (v.payments_banks.filter(pv => pv.payment.method === 'card').length > 0) {
        const pym = v.payments_banks.filter(pv => pv.payment.method === 'card')[0]
        this.payments.push({
          name: '(' + v.name + ') ' + this.$vuetify.lang.t('$vuetify.payment.' + pym.payment.method),
          type: pym.payment.name,
          method: pym.payment.method,
          bank_id: v.id,
          payment_id: pym.id
        })
      }
      v.payments_banks.forEach((p) => {
        if (!this.showAll) {
          if (p.payment.method !== 'credit') {
            this.payments.push({
              name: '(' + v.name + ') ' + this.$vuetify.lang.t('$vuetify.payment.' + p.payment.method),
              type: p.payment.name,
              method: p.payment.method,
              bank_id: v.id,
              payment_id: p.id
            })
          }
        } else if (p.payment.method !== 'card' && p.payment.method !== 'cash') {
          this.payments.push({
            name: '(' + v.name + ') ' + this.$vuetify.lang.t('$vuetify.payment.' + p.payment.method),
            type: p.payment.name,
            method: p.payment.method,
            bank_id: v.id,
            payment_id: p.id
          })
        }
      })
    })
  },
  methods: {
    ...mapActions('payment', ['toogleNewModal']),
    ...mapActions('exchangeRate', ['getChanges']),
    ...mapActions('bank', ['getBanks']),
    savePayment () {
      if (this.pay.bank_payment.method === 'cash') {
        const cantPay = this.pay.currency_id ? this.pay.cant_pay * this.pay.currency.change : this.pay.cant_pay
        if (this.pay.cant > cantPay) {
          this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.delete', [
                this.$vuetify.lang.t('$vuetify.menu.pay')
              ]),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_change_cant_pay'
              ),
              icon: 'info',
              showCancelButton: false,
              cancelButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.cancel'
              ),
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.save'
              ),
              confirmButtonColor: 'red'
            })
            .then(() => {
              this.pay.cant = this.pay.cant_pay
              this.pay.cant_back = 0.00
              this.$emit('addPayment', this.pay)
            })
        } else {
          this.$emit('addPayment', this.pay)
        }
      } else if (this.pay.bank_payment.method === 'credit') {
        this.pay.cant_pay = 0.00
        this.$emit('addPayment', this.pay)
      } else {
        this.pay.cant = this.pay.cant_pay
        this.$emit('addPayment', this.pay)
      }
    },
    cancelChangeCant () {
      this.pay.cant = parseFloat(this.pending).toFixed(2)
    },
    cancelChangeCantPay () {
      if (this.pay.bank_payment) {
        if (this.pay.bank_payment.method !== 'cash') {
          if (this.pay.cant_pay > this.pending) {
            this.pay.cant_pay = parseFloat(this.pending).toFixed(2)
            this.pay.cant = parseFloat(this.pending).toFixed(2)
          } else {
            this.pay.cant = this.pay.cant_pay
          }
        }
      } else {
        this.pay.cant_pay = this.pending
      }
      this.pay.cant_pay = parseFloat(this.pay.cant_pay).toFixed(2)
    },
    getExactValue () {
      const cantP = this.pay.cant / this.pay.currency.change
      return {
        six_decimals: Math.round((this.pay.cant / this.pay.currency.change) * 1000000) / 1000000,
        two_decimals: Math.round(cantP * 100) / 100,
        lost: parseFloat(Math.round(cantP * 100) / 100 * this.pay.currency.change).toFixed(2),
        difference: parseFloat(Math.round(cantP * 100) / 100 * this.pay.currency.change - this.pay.cant).toFixed(2),
        cant_back: parseFloat(this.cantToPay * this.pay.currency.change - this.pay.cant).toFixed(2)
      }
    },
    customFilter (item, queryText, itemText) {
      return (
        this.$vuetify.lang
          .t('$vuetify.payment.' + item.method)
          .toLowerCase()
          .indexOf(queryText.toLowerCase()) > -1
      )
    },
    calcDifference () {
      this.pay.cant_back =
          this.currencies.length > 0
            ? this.pay.cant_pay * this.pay.currency.change -
              this.pay.cant
            : this.pay.cant_pay - this.pay.cant
      if (this.pay.cant_pay === 0.00) { this.pay.cant_back = 0.00 }
    },
    lettersNumbers (event) {
      const regex = new RegExp('^[a-zA-Z0-9 ]+$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>

<style scoped></style>
